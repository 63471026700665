import * as styles from "./contact-success.module.scss";

import Banner from "../components/Banner";
import Layout from "../components/Layout";
import { Link } from "gatsby";
import React from "react";
import SeoHelmet from "../components/SeoHelmet";

export default function ContactSuccess() {
  return (
    <Layout>
      <SeoHelmet title="Roger wilco" />
      <Banner title="Lithic Tech" subtitle="Build Apps That Rock" image="landing" />
      <div className={styles.pageContent}>
        <div className={styles.success}>
          <p className={styles.text}>
            Thanks! We got your message and will get back to you soon.
          </p>
          <p className={styles.text}>
            <Link to="/">Home</Link>
          </p>
        </div>
      </div>
    </Layout>
  );
}
